import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { formatToMoney } from '@app/helpers';
import {
  AnyElement,
  IDelegate,
  IGuestHouse,
  IOrder,
  IReportPresentation,
  IBank,
  IAccompanyingPerson,
  IStandInformation,
} from '@app/types/generalTypes';
import { Avatar, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TFunction } from 'i18next';
import moment from 'moment';

export const DefaultDelegate: IDelegate = {
  abstracts: {
    approve: 0,
    created_at: '',
    description: '',
    files: '',
    files_path: '',
    full_name: '',
    id: '',
    rate: 0,
    status: 0,
    title: '',
  },
  address: '',
  avatar: '',
  avatar_path: '',
  company: '',
  created_at: '',
  description: '',
  email: '',
  emergency_contact: {
    created_at: '',
    email: '',
    fullname: '',
    id: '',
    name: '',
    phone: '',
  },
  id: '',
  job: '',
  orders: {
    banks_id: '',
    created_at: '',
    description: '',
    full_name: '',
    id: '',
    payment_amount: '',
    status_payment: '',
  },
  phone: '',
  private_information: {
    created_at: '',
    diet_id: '',
    food_description: '',
    food_id: '',
    id: '',
    medical_description: '',
    shirt_size_id: '',
  },
  register_conference: {
    company: '',
    country: '',
    created_at: '',
    email: '',
    fullname: '',
    id: '',
    identification: '',
    information_from: '',
    information_to: '',
    is_government: 0,
    is_register_car: 0,
    job_title: '',
    name: '',
    phone: '',
    registration_form_id: '',
    transport: '',
    pick_up_point: '',
  },
  accompanying_person: [
    // {
    //   Name: 'chuong',
    //   Relationship_with_delegates: 'Anh',
    // },
  ],
};

const getStatusOrder = (status: any) => {
  if (status === 0) {
    return <Tag color={'red'}>{'Chưa Thanh Toán'}</Tag>;
  } else if (status === 1) {
    return <Tag color={'green'}>{'Chờ xác nhận'}</Tag>;
  } else if (status === 2) {
    return <Tag color={'green'}>{'Đã thanh toán'}</Tag>;
  } else if (status === 3) {
    return <Tag color={'red'}>{'Đã hủy'}</Tag>;
  }
};

export const getColumnOrder = ({ onEdit }: { onEdit: (record: IOrder) => void }): ColumnsType<IOrder> => {
  const renderPaymentType = (type: string) => {
    if (type === 'Vnpay') return 'VNPAY';
    if (type === 'transfer') return 'Chuyển khoản';
    else return 'Chưa xác định';
  };
  return [
    {
      title: 'STT',
      key: 'STT',
      width: '5%',
      render: (value, record, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: 'Đại biểu',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Mã đơn hàng',
      key: 'txnRef',
      render: (value) => {
        return <div>{value?.vnpay_order?.[0]?.vnp_TxnRef}</div>;
      },
    },
    {
      title: 'Số tiền thanh toán',
      dataIndex: 'payment_amount',
      key: 'payment_amount',
      render: (amount: string) => <div>{formatToMoney(amount)}</div>,
    },
    {
      title: 'Hình thức',
      dataIndex: 'payment_type',
      key: 'payment_type',
      render: (type: string) => <div>{renderPaymentType(type)}</div>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status_payment',
      key: 'status_payment',
      render: (status: number) => {
        return getStatusOrder(status);
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => {
        return moment(date).format('DD/MM/YYYY');
      },
    },
    {
      title: 'Tác vụ',
      dataIndex: 'actions',
      width: '6%',
      key: 'actions',
      render: (_: unknown, record: IOrder) => (
        <div
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          <BaseButton icon={<EditOutlined />} size="small" onClick={() => onEdit(record)} />
          {/* <BaseButton icon={<DeleteOutlined />} size="small" onClick={() => onDelete(record)} /> */}
        </div>
      ),
    },
  ];
};

export const getColumnAccompanyingPerson = ({
  onEdit,
}: {
  onEdit: (record: IAccompanyingPerson) => void;
}): ColumnsType<IAccompanyingPerson> => {
  return [
    {
      title: 'Tên người đi cùng',
      dataIndex: 'fullname',
      key: 'fullname',
    },
    {
      title: 'Mối quan hệ',
      dataIndex: 'relationship_with_delegates',
      key: 'relationship_with_delegates',
    },
    {
      title: 'Tên đại biểu',
      dataIndex: 'delegate_name',
      key: 'delegate_name',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => {
        return moment(date).format('DD/MM/YYYY');
      },
    },
    {
      title: 'Tác vụ',
      dataIndex: 'actions',
      width: '14%',
      key: 'actions',
      render: (_: unknown, record: IAccompanyingPerson) => (
        <div
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          <BaseButton size="small" onClick={() => onEdit(record)}>
            Thông tin đại biểu
          </BaseButton>
        </div>
      ),
    },
  ];
};

export const getColumnStandInformation = (): ColumnsType<IStandInformation> => {
  return [
    {
      title: 'STT',
      key: 'STT',
      width: '14%',
      render: (value, record, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: 'Tên gian hàng',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tên Đại biểu',
      dataIndex: 'delegateName',
      key: 'delegateName',
    },
    {
      title: 'Giá',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: string) => <div>{formatToMoney(amount)}</div>,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => {
        return moment(date).format('DD/MM/YYYY');
      },
    },
  ];
};

export const getColumnDelegate = (
  t: TFunction,
  actions: { onEdit: (record: IDelegate) => void; onDelete: (record: IDelegate) => void },
): ColumnsType<IDelegate> => {
  const { onDelete, onEdit } = actions;

  return [
    {
      title: 'STT',
      key: 'STT',
      width: '12.5%',
      render: (value, record, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: t('columns.image') as string,
      dataIndex: 'avatar',
      width: '12.5%',
      key: 'avatar',
      render: (image: string, record: IDelegate) => {
        return <Avatar icon={<img src={record.avatar_path} alt="avatar" />}></Avatar>;
      },
    },
    {
      title: t('columns.name') as string,
      dataIndex: 'register_conference.fullname',
      key: 'register_conference.fullname',
      width: '12.5%',
      render: (image: string, record: IDelegate) => {
        return <div>{record.register_conference.fullname}</div>;
      },
    },
    {
      title: 'Quốc gia',
      dataIndex: 'register_conference.country',
      key: 'register_conference.country',
      width: '12.5%',
      render: (image: string, record: IDelegate) => {
        return <div>{record.register_conference.country}</div>;
      },
    },
    {
      title: 'Đơn vị',
      dataIndex: 'company',
      key: 'company',
      width: '12.5%',
      render: (image: string, record: IDelegate) => {
        return <div>{record.company}</div>;
      },
    },
    {
      title: 'Báo cáo',
      dataIndex: 'abstracts',
      key: 'abstracts',
      width: '12.5%',
      render: (abstracts: AnyElement) => {
        return abstracts?.files_path ? (
          <a href={abstracts?.files_path} download={abstracts?.files_path}>
            Dowload
          </a>
        ) : null;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status_payment',
      width: '12.5%',
      key: 'status_payment',
      render: (image: string, record: IDelegate) => {
        return getStatusOrder(record.orders.status_payment);
      },
    },
    {
      title: t('columns.createdAt') as string,
      dataIndex: 'created_at',
      width: '12.5%',
      key: 'created_at',
      render: (date: string) => {
        return moment(date).format('DD/MM/YYYY');
      },
    },
    {
      title: t('columns.actions') as string,
      dataIndex: 'actions',
      width: '12.5%',
      key: 'actions',
      render: (_: unknown, record: IDelegate) => (
        <div
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          <BaseButton icon={<EditOutlined />} size="small" onClick={() => onEdit(record)} />
          <BaseButton icon={<DeleteOutlined />} size="small" onClick={() => onDelete(record)} />
        </div>
      ),
    },
  ];
};

export const getColumnReportPresentation = (
  t: TFunction,
  actions: { onEdit: (record: IReportPresentation) => void },
): ColumnsType<IReportPresentation> => {
  const { onEdit } = actions;
  return [
    {
      title: 'STT',
      key: 'STT',
      width: '14%',
      render: (value, record, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: 'Tiêu đề báo cáo',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Tên đại biểu',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => {
        return moment(date).format('DD/MM/YYYY');
      },
    },
    {
      title: 'Báo cáo',
      dataIndex: 'files_path',
      key: 'files_path',
      render: (files_path: string) => {
        return files_path ? (
          <a href={files_path} download={files_path}>
            Download
          </a>
        ) : null;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => (
        <Tag color={status === 1 ? 'green' : 'red'}>{status === 1 ? 'Đã duyệt' : 'Chờ xét duyệt'}</Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (_: unknown, record: IReportPresentation) => (
        <div
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          <BaseButton icon={<EditOutlined />} size="small" onClick={() => onEdit(record)} />
          {/* <BaseButton icon={<DeleteOutlined />} size="small" onClick={() => onDelete(record)} /> */}
        </div>
      ),
    },
  ];
};

export const getColumnGuestHouse = ({
  onDelete,
  onEdit,
}: {
  onEdit: (record: IGuestHouse) => void;
  onDelete: (record: IGuestHouse) => void;
}): ColumnsType<IGuestHouse> => {
  return [
    {
      title: 'STT',
      key: 'STT',
      width: '14%',
      render: (value, record, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: 'Ảnh',
      dataIndex: 'image_path',
      key: 'image_path',
      render: (image) => {
        return <Avatar src={<img src={image} alt="avatar" />} />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Đánh giá',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      width: '16.5%',
      key: 'actions',
      align: 'center',
      render: (_: AnyElement, record: IGuestHouse) => (
        <div
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          <BaseButton icon={<EditOutlined />} size="small" onClick={() => onEdit(record)} />
          <BaseButton icon={<DeleteOutlined />} size="small" onClick={() => onDelete(record)} />
        </div>
      ),
    },
  ];
};

export const getColumnBanks = ({ onEdit }: { onEdit: (record: IBank) => void }): ColumnsType<IBank> => {
  return [
    {
      title: 'Chủ tài khoản',
      dataIndex: 'account_name',
      key: 'account_name',
      width: '14%',
      render: (image: string, record: IBank) => {
        return <div>{record.account_name}</div>;
      },
    },
    {
      title: 'Số tài khoản',
      dataIndex: 'account_number',
      key: 'account_number',
      width: '14%',
      render: (image: string, record: IBank) => {
        return <div>{record.account_number}</div>;
      },
    },
    {
      title: 'Tên ngân hàng',
      dataIndex: 'bank_name',
      key: 'bank_name',
      width: '14%',
      render: (image: string, record: IBank) => {
        return <div>{record.bank_name}</div>;
      },
    },
    {
      title: 'Chi nhánh',
      dataIndex: 'branch',
      key: 'branch',
      width: '14%',
      render: (image: string, record: IBank) => {
        return <div>{record.branch}</div>;
      },
    },
    {
      title: 'Ngảy tạo' as string,
      dataIndex: 'created_at',
      width: '14%',
      key: 'created_at',
      render: (date: string) => {
        return moment(date).format('DD/MM/YYYY');
      },
    },
    {
      title: 'Tác vụ' as string,
      dataIndex: 'actions',
      width: '14%',
      key: 'actions',
      render: (_: unknown, record: IBank) => (
        <div
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          <BaseButton icon={<EditOutlined />} size="small" onClick={() => onEdit(record)} />
        </div>
      ),
    },
  ];
};
