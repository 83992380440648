import { CarOutlined, UnorderedListOutlined } from '@ant-design/icons';
import React from 'react';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  role?: string;
}
export const sidebarNavigationUser: SidebarNavigationItem[] = [
  {
    title: 'Báo báo tham luận',
    key: 'feed',
    url: '/delegate/presentations',
    icon: <UnorderedListOutlined />,
  },
];
export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Đại biểu',
    key: 'delegate',
    icon: <UnorderedListOutlined />,
    children: [
      {
        title: 'Đại biểu',
        key: 'delegate1',
        url: '/delegate',
      },
      {
        title: 'Báo báo tham luận',
        key: 'feed',
        url: '/delegate/presentations',
      },
    ],
  },
  {
    title: 'Thông tin người đi cùng',
    key: 'AccompanyingPerson',
    url: '/accompanying-person',
    icon: <UnorderedListOutlined />,
  },
  {
    title: 'Order',
    key: 'orders',
    url: '/orders',
    icon: <UnorderedListOutlined />,
  },
  {
    title: 'Nhà khách',
    key: 'GuesthouseInformation',
    url: '/guest-house',
    icon: <CarOutlined />,
  },
  {
    title: 'Thông tin gian hàng',
    key: 'StandInformation',
    url: '/stand-information',
    icon: <UnorderedListOutlined />,
  },
];
