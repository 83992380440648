import { UploadOutlined } from '@ant-design/icons';
import { getGuestHouseServiceApi, postGuestHouseApi, putGuestHouseApi } from '@app/api/v1/GuestHouseApi';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { notificationController } from '@app/controllers/notificationController';
import { AnyElement, IGuestHouse } from '@app/types/generalTypes';
import { getBase64 } from '@app/utils/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Col, Form, Rate, Row } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload';
import { FC, useState } from 'react';
import { BannerImage, ImagePreview, WrapperImageAvatar } from '../CreateDelegate/styles';
type GuestHouseProps = {
  isOpen: boolean;
  onCancel: () => void;
  defaultValue: IGuestHouse;
};
const GuestHouseModel: FC<GuestHouseProps> = ({ isOpen, onCancel, defaultValue }) => {
  const [form] = Form.useForm();
  const [previewImage, setPreviewImage] = useState(defaultValue?.image_path ?? '');
  const [fileAvatar, setFileAvatar] = useState<RcFile>();
  const queryClient = useQueryClient();

  const { data: guestHouseService, isLoading } = useQuery({
    queryKey: ['getGuestHouseServiceApi'],
    queryFn: getGuestHouseServiceApi,
  });

  const createMutation = useMutation({
    mutationFn: postGuestHouseApi,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['GuestHouse'] });
      notificationController.success({
        message: 'Tạo mới nhà khách thành công!!!',
      });
      onCancel();
    },
  });
  const updateMutation = useMutation({
    mutationFn: putGuestHouseApi,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['GuestHouse'] });
      notificationController.success({
        message: 'Update nhà khách thành công!!!',
      });
      onCancel();
    },
  });

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = (values: IGuestHouse) => {
    if (!defaultValue.id) {
      createMutation.mutate({
        ...values,
        image: fileAvatar,
        guesthouse_service: guestHouseService?.filter((item) =>
          values.guesthouse_service?.includes(item?.id as AnyElement),
        ),
      });
    } else {
      updateMutation.mutate({
        ...defaultValue,
        ...values,
        image: fileAvatar,
        guesthouse_service: guestHouseService?.filter((item) =>
          values.guesthouse_service?.includes(item?.id as AnyElement),
        ),
      });
    }
  };

  const propsUpload: UploadProps = {
    name: 'file',
    showUploadList: false,
    beforeUpload: async (file) => {
      const isPNG = file.type === 'image/png' || file.type === 'image/jpeg';
      if (!isPNG) {
        notificationController.error({
          message: 'Bạn chỉ có thể upload file ảnh!',
        });
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        notificationController.error({ message: 'Ảnh upload có dung lượng tối đa 10M' });
        return false;
      }
      const preview = await getBase64(file);
      setFileAvatar(file);
      setPreviewImage(preview);

      return false;
    },
  };

  return (
    <BaseModal title="Tạo mới nhà khách" open={isOpen} onOk={handleSubmit} onCancel={onCancel} style={{ top: '10px' }}>
      <Form form={form} layout="vertical" initialValues={defaultValue} onFinish={onFinish}>
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              name="image"
              hasFeedback
              rules={[
                {
                  required: defaultValue?.image_path ? false : true,
                  message: 'Vui lòng chọn 1 ảnh hiển thị. không sử dụng ảnh hiển thị mặc định!',
                },
              ]}
            >
              <WrapperImageAvatar>
                <BannerImage style={{ marginBottom: 10 }}>
                  <ImagePreview src={previewImage ? previewImage : 'https://www.apgn2024.vn/images/hotel-3.png'} />
                </BannerImage>
                <BaseUpload {...propsUpload}>
                  <BaseButton icon={<UploadOutlined />}>Tải ảnh lên</BaseButton>
                </BaseUpload>
              </WrapperImageAvatar>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              label={'Tên nhà khách'}
              rules={[{ required: true, message: 'Vui lòng nhập tên nhà khách!' }]}
            >
              <BaseInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="amount"
              label={'Giá tiền'}
              rules={[{ required: true, message: 'Vui lòng nhập số lượng!' }]}
            >
              <BaseInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="link"
              label={'Link trang web nhà khách'}
              rules={[{ required: true, message: 'Vui lòng nhập số lượng!' }]}
            >
              <BaseInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="guesthouse_service"
              label={'Dịch vụ'}
              rules={[{ required: true, message: 'Vui lòng chọn ít nhất 1 dịch vụ!' }]}
            >
              <BaseSelect
                mode="multiple"
                loading={isLoading}
                options={guestHouseService?.map((item) => ({ label: item.name, value: item.id }))}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="rate"
              label={'Đánh giá sao'}
              rules={[{ required: true, message: 'Vui lòng đánh giá nhà khách!' }]}
            >
              <Rate style={{ fontSize: 24 }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="description"
              label={'Mô tả'}
              rules={[{ required: true, message: 'Vui lòng nhập mô tả nhà khách!' }]}
            >
              <BaseInput />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </BaseModal>
  );
};

export default GuestHouseModel;
