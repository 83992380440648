import styled from 'styled-components';

export const DelegatePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DelegateHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
`;
export const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
export const RemoveImageBtn = styled.div`
  text-align: center;
  cursor: pointer;
  background: #0060b9;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -15px;
  right: -25px;
  z-index: 1;

  & svg {
    fill: white;
  }
`;

export const BoxEmail = styled.div`
  padding: 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 12px;
  margin-top: 30px;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const FunctionHeader = styled.div`
  display: flex;
  gap: 20;
  justify-content: space-between;
  &.justify-center {
    justify-content: center;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.justify-end {
    justify-content: end;
  }
`;
export const Flex = styled.div`
  display: flex;
`;

export const Content = styled.div`
  display: flex;
`;
