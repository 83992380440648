import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SidebarNavigationItem, sidebarNavigation, sidebarNavigationUser } from '../sidebarNavigation';
import * as S from './SiderMenu.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);
const sidebarNavFlatUser = sidebarNavigationUser.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);
const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const location = useLocation();
  const user = useAppSelector((state) => state.user.profile);

  const currentMenuItem = useMemo(() => {
    if (user?.role === 'user') {
      return sidebarNavFlatUser.find(({ url }) => url === location.pathname);
    }
    return sidebarNavFlat.find(({ url }) => url === location.pathname);
  }, [location.pathname, user?.role]);

  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = useMemo(() => {
    if (user?.role === 'user') {
      return sidebarNavigationUser.find(({ url }) => url === location.pathname);
    }
    return sidebarNavigation.find(({ children }) => children?.some(({ url }) => url === location.pathname));
  }, [location.pathname, user?.role]);

  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  const items = useMemo(() => {
    if (user?.role === 'user') {
      return sidebarNavigationUser.map((nav) => {
        const isSubMenu = nav.children?.length;

        return {
          key: nav.key,
          title: nav.title,
          label: isSubMenu ? nav.title : <Link to={nav.url || ''}>{nav.title}</Link>,
          icon: nav.icon,
          children:
            isSubMenu &&
            nav.children &&
            nav.children.map((childNav) => ({
              key: childNav.key,
              label: <Link to={childNav.url || ''}>{childNav.title}</Link>,
              title: nav.title,
            })),
        };
      });
    }
    return sidebarNavigation.map((nav) => {
      const isSubMenu = nav.children?.length;

      return {
        key: nav.key,
        title: nav.title,
        label: isSubMenu ? nav.title : <Link to={nav.url || ''}>{nav.title}</Link>,
        icon: nav.icon,
        children:
          isSubMenu &&
          nav.children &&
          nav.children.map((childNav) => ({
            key: childNav.key,
            label: <Link to={childNav.url || ''}>{childNav.title}</Link>,
            title: nav.title,
          })),
      };
    });
  }, [user?.role]);

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={() => setCollapsed(true)}
      items={items}
    />
  );
};

export default SiderMenu;
