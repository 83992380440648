import axiosClient from '@app/config/axios';
import { AnyElement, IGuestHouse, IGuestHouseService } from '@app/types/generalTypes';
import axios from 'axios';

export const getGuestHouseApi = async (): Promise<IGuestHouse[]> => {
  const res = await axiosClient.get(`api/GuesthouseInformation/GetAll`);
  return res.data.map((item: IGuestHouse) => {
    return {
      ...item,
      guesthouse_service: item.guesthouse_service ? item.guesthouse_service.map((item) => item.id) : [],
    };
  });
};

export const postGuestHouseApi = async (data: AnyElement): Promise<AnyElement> => {
  const bodyFormData = new FormData();

  for (const key in data) {
    if (data[key]) {
      if (key === 'guesthouse_service') {
        const guesthouse_service: AnyElement[] = data[key];
        guesthouse_service.forEach((item, index) => {
          const idTempt = `Guesthouse_service[${index}].Id`;
          bodyFormData.append(idTempt, item.id);
          const nameTempt = `Guesthouse_service[${index}].Name`;
          bodyFormData.append(nameTempt, item.name);
        });
      } else {
        bodyFormData.append(key, data[key]);
      }
    }
  }

  const res = await axios({
    method: 'post',
    url: 'https://api.apgn2024.vn/api/GuesthouseInformation/Add',
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return res;
};

export const putGuestHouseApi = async (data: AnyElement): Promise<AnyElement> => {
  const bodyFormData = new FormData();

  for (const key in data) {
    if (data[key]) {
      if (key === 'guesthouse_service') {
        const guesthouse_service: AnyElement[] = data[key];
        guesthouse_service.forEach((item, index) => {
          const idTempt = `Guesthouse_service[${index}].Id`;
          bodyFormData.append(idTempt, item.id);
          const nameTempt = `Guesthouse_service[${index}].Name`;
          bodyFormData.append(nameTempt, item.name);
        });
      } else {
        bodyFormData.append(key, data[key]);
      }
    }
  }

  const res = await axios({
    method: 'put',
    url: 'https://api.apgn2024.vn/api/GuesthouseInformation/Update',
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return res;
};

export const deleteGuestHouseApi = async (id: string): Promise<AnyElement> => {
  const res = await axiosClient.delete(`api/GuesthouseInformation/Delete`, {
    params: {
      id: id,
    },
  });
  return res.data;
};

export const getGuestHouseServiceApi = async (): Promise<IGuestHouseService[]> => {
  const res = await axiosClient.get(`https://api.apgn2024.vn/api/GuesthouseService/GetAll`);
  return res.data;
};
