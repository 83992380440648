import { DefaultDelegate } from '@app/constants/tableColumns';
import { createSlice } from '@reduxjs/toolkit';
import { IAccompanyingPerson, IDelegate } from '@app/types/generalTypes';

export enum ModeType {
  UPDATE,
  CREATE,
}

export interface IDelegateSlice {
  focusData: IAccompanyingPerson;
  detailAccompany: IAccompanyingPerson;
  mode: ModeType;
}

const initialState: any = {
  focusData: DefaultDelegate,
  detailAccompany: DefaultDelegate,
  mode: ModeType.CREATE,
};

const detailDelegateSlice = createSlice({
  name: 'detailDelegate',
  initialState,
  reducers: {
    setDataFocus(state, action: { payload: IAccompanyingPerson }) {
      state.focusData = action.payload;
    },
    setMode(state, action: { payload: ModeType }) {
      state.mode = action.payload;
    },
    setDetail(state, action: { payload: any }) {
      state.detailAccompany = action.payload;
    },
  },
});
export const { setDataFocus, setMode, setDetail } = detailDelegateSlice.actions;
export default detailDelegateSlice.reducer;
