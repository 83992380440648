import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { IGuestHouse } from '@app/types/generalTypes';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  refData: IGuestHouse;
  onOk: () => void;
  onCancel: () => void;
};

const GuestHouseDelete: React.FC<Props> = ({ open, refData, onOk, onCancel }) => {
  const { t } = useTranslation();
  return (
    <BaseModal title={t('modal.delete_title')} open={open} onOk={onOk} onCancel={onCancel}>
      <p>
        Bạn có chắc băn xóa nhà khách id là: <strong>{refData.id}</strong>, có tên là: <strong>{refData.name}</strong>
        không?
      </p>
    </BaseModal>
  );
};
export default memo(GuestHouseDelete);
