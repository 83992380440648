import { PlusOutlined } from '@ant-design/icons';
import { deleteGuestHouseApi, getGuestHouseApi } from '@app/api/v1/GuestHouseApi';
import { Flex, FunctionHeader, PageWrapper } from '@app/components/apps/Delegate/style';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { getColumnGuestHouse } from '@app/constants/tableColumns';
import { IGuestHouse } from '@app/types/generalTypes';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Content } from 'antd/lib/layout/layout';
import { FC, useMemo, useState } from 'react';
import GuestHouseDelete from './GuestHouseDelete';
import GuestHouseModel from './GuestHouseModel';
import { notificationController } from '@app/controllers/notificationController';

export const DefaultGuestHouse: IGuestHouse = {
  amount: '',
  conference_information_id: '',
  description: '',
  name: '',
  rate: 5,
};

const GuestHouse: FC = () => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [dataFocus, setDataForcus] = useState<IGuestHouse>(DefaultGuestHouse);

  const onDelete = (record: IGuestHouse) => {
    setDataForcus(record);
    setIsOpenDelete(true);
  };
  const onEdit = (record: IGuestHouse) => {
    setDataForcus(record);
    setIsOpen(true);
  };

  const columns = useMemo(() => {
    return getColumnGuestHouse({
      onDelete,
      onEdit,
    });
  }, []);

  const deleteMutation = useMutation({
    mutationFn: deleteGuestHouseApi,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['GuestHouse'] });
      notificationController.success({
        message: 'Xóa nhà khách thành công!!!',
      });
      setDataForcus(DefaultGuestHouse);
      setIsOpenDelete(false);
    },
  });

  const { isLoading, data } = useQuery({
    queryKey: ['GuestHouse'],
    queryFn: getGuestHouseApi,
  });

  return (
    <PageWrapper>
      <FunctionHeader className="justify-end">
        <Flex>
          <BaseButton
            size="small"
            style={{
              fontSize: '12px',
            }}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsOpen(true)}
          >
            Thêm mới
          </BaseButton>
        </Flex>
      </FunctionHeader>

      <Content>
        <BaseTable
          columns={columns}
          dataSource={data ?? []}
          rowKey="id"
          loading={isLoading}
          // onChange={handleTableChange}
          // pagination={{
          //   pageSize: pagination.pageSize,
          //   showSizeChanger: true,
          //   current: pagination.current,
          //   showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          // }}
        />
      </Content>
      {isOpen ? (
        <GuestHouseModel
          defaultValue={dataFocus}
          isOpen={isOpen}
          onCancel={() => {
            setIsOpen(false);
            setDataForcus(DefaultGuestHouse);
          }}
        />
      ) : null}
      {isOpenDelete ? (
        <GuestHouseDelete
          onCancel={() => {
            setIsOpenDelete(false);
          }}
          onOk={() => {
            if (dataFocus.id) {
              deleteMutation.mutate(dataFocus?.id);
            }
          }}
          open={isOpenDelete}
          refData={dataFocus}
        />
      ) : null}
    </PageWrapper>
  );
};

export default GuestHouse;
